.measure {
  margin-left: -15px;
  border: #28a74529 solid 1px;
  &-info {
    width: 100%;
    padding: 10px;
    &__battery {
      span {
        float: right;
      }
    }
  }
  &-temperature {
    float: left;
    padding: 34px;
    &__icon {
      padding: 0 !important;
    }
    &__text {
      font-size: 4.5rem;
      margin-left: -15px;
      padding: 0 !important;
      span {
        font-size: small;
        position: absolute;
      }
    }
  }
}
.measure-detail {
  border: #28a74529 solid 1px;
  font-size: 14px;
  &__text {
    padding: 25px 15px 3px 15px;
    margin-right: 20px;
    margin-left: 8px;
  }
  &__map {
    margin-left: -13px;
    padding: 10px 15px 3px 15px;
  }
}
