.container-app {
  padding: 0;
  .choose-device {
    margin-top: 5%;
    bottom: 10;
  }

  .charts {
    &__latest {
      margin-top: 5%;
      padding: 0 25px;
    }
    &__histogram {
      margin-top: 3%;
      padding: 0 25px 50px 25px;
      border: #28a74529 solid 1px;
      margin-bottom: 15px;
      margin-left: -8px;
    }
  }
}
